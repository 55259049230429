import { Fragment } from "react";
import "./WhyChooseUs.scss";
import Slider from "react-slick";
import { RxDashboard } from "react-icons/rx";

const WhyChooseUs = ({secId}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    speed:20000,
    autoplaySpeed:1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint:1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll:1,
          infinite: true,
        },
      },
      {
        breakpoint:850,
        settings: {
          slidesToShow: 2,
          slidesToScroll:1,
        },
      },
      {
        breakpoint:550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cardData = [
    {
      id: 0,
      img: <RxDashboard />,
      title: "lorem ipsum amet",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum repellat adipisci soluta hic suscipit est.",
    },
    {
      id: 1,
      img: <RxDashboard />,
      title: "lorem ipsum amet",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum repellat adipisci soluta hic suscipit est.",
    },
    {
      id: 2,
      img: <RxDashboard />,
      title: "lorem ipsum amet",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum repellat adipisci soluta hic suscipit est.",
    },
    {
      id: 3,
      img: <RxDashboard />,
      title: "lorem ipsum amet",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum repellat adipisci soluta hic suscipit est.",
    },
    {
      id: 4,
      img: <RxDashboard />,
      title: "lorem ipsum amet",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum repellat adipisci soluta hic suscipit est.",
    },
    {
      id: 5,
      img: <RxDashboard />,
      title: "lorem ipsum amet",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum repellat adipisci soluta hic suscipit est.",
    },
    {
      id: 6,
      img: <RxDashboard />,
      title: "lorem ipsum amet",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum repellat adipisci soluta hic suscipit est.",
    },
  ];

  return (
    <section className="why_choose_title p_b" id={secId}>
      <div className="content_wrap p_t">
        <div className="wow fadeInUp">
        <h2 className="gradient dark">Why Choose Us</h2>
        </div>
        <p className="wow fadeInUp" data-wow-delay="0.1s">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti
          natus voluptatibus ducimus saepe illo quos?
        </p>
      </div>
      <div className="slide_wraper">
        <Slider {...settings}>
          {cardData.map((data) => {
            return (
                <div className="card_wraper" key={data.id}>
              <div className="card">
                {data.img}
                <h3>{data.title}</h3>
                <p>{data.text}</p>
              </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default WhyChooseUs;
