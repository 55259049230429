
import { NavLink } from "react-router-dom";
import "./SubCompanyFooter.scss";
import cookiePdf from "../../assets/pdfs/Cookie_Policy.pdf";

const SubCompanyFooter = ({ logo, address, disclaimer, link1, linktext1, email, mecred  }) => {
  return (
    <footer className="company">
      <section className="subcompany_footer">
        <div className="content_wrap">
          <div className="footer_grid">
          
          <div className="logo_wrap">
            <img src={logo} alt="logo" />
          </div>
          <div className="col_sec">
            <h3>USEFUL LINKS</h3>            
             <NavLink to={link1}>{linktext1}</NavLink>    
             {
              mecred && <a href={cookiePdf} target="_blank">Cookie Policy</a>       
             }
             
          </div>      
     
          <div className="col_sec">
            <h3>ADDRESS</h3>
          <p>{address}</p>

          <div className="info">
            <div className="in_line">
            <p>Email: </p>
            <a href={`mailto:${email}`}>{email}</a>
            </div>
          </div>
          </div>

          </div>
        </div>
        <div className="content_wrap">
    {disclaimer && disclaimer}
        </div>
      </section>
    </footer>
  );
};
export default SubCompanyFooter;
