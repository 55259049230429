import "./Header.scss";
import { images } from "../../utils/images";
import { MdOutlineMenu } from "react-icons/md";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  const [status, setStatus] = useState(false);

  const location = useLocation();

  return (
    <header>
      <section className="top_header">
        <div className="content_wrap">
          <div className="logo_wrap">
            <a href="/">
              <img src={images.finneva} alt="logo" className="finneva" />
            </a>
          </div>
          <nav>
            <NavLink to="/">Home</NavLink>
            <button
              type="button"
              className={`pop_over ${
                location.pathname.includes("/solutions") ? "active" : ""
              }`}
            >
              Our Solutions
              <div className="sub_menu">
                <NavLink to="/solutions/businesses">For Businesses</NavLink>
                <NavLink to="/solutions/institution">
                  For&nbsp;Financial&nbsp;Institutions
                </NavLink>
              </div>
            </button>
            <HashLink to="/#aboutus"  scroll={(el) => el.scrollIntoView({ behavior: 'smooth'})}>About Us</HashLink>
            <NavLink to="/contactus">Contact Us</NavLink>
          </nav>

          <div className="right">
            <button className="menu" onClick={() => setStatus(true)}>
              <MdOutlineMenu />
            </button>
          </div>
        </div>
      </section>

      <div className={`drower ${status ? "open" : "close"}`}>
        <button className="close" onClick={() => setStatus(false)}>
          <IoClose />
        </button>
        <a href="/">
          <img src={images.finneva} alt="logo" className="finneva" />
        </a>
        <nav>
          <NavLink to="/" onClick={() => setStatus(false)}>
            Home
          </NavLink>

          <div className="solution_wrap">
            <p
              className={`${
                location.pathname.includes("/solutions") ? "active" : ""
              }`}
            >
              Our Solutions
            </p>
            <div className="sub_nav">
              <NavLink to="/solutions/businesses">For Businesses</NavLink>
              <NavLink to="/solutions/institution">
                For&nbsp;Financial&nbsp;Institutions
              </NavLink>
            </div>
          </div>

          <HashLink to="/#aboutus">About Us</HashLink>
          <NavLink to="/contactus" onClick={() => setStatus(false)}>
            Contact Us
          </NavLink>
      
        </nav>
      </div>
    </header>
  );
};

export default Header;
