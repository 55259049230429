import { useEffect } from "react";
import PageLayout from "../../lauouts/pagelayout/PageLayout";
import "./OurSolutions.scss";
import Counters from "./counters/Counters";
import InfoLR from "./infoLR/InfoLR";
import LogoSlider from "./logoslider/LogoSlider";
import MultiGrid from "./multigrid/MultiGrid";
import POV from "./pov/POV";
import RequestDemo from "./requestdemo/RequestDemo";
import SolutionLanding from "./solutionLanding/SolutionLanding";
import WOW from "wowjs";

const OurSolutions = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <PageLayout>
      <SolutionLanding />
      <InfoLR
        title="lorem ipsum"
        para1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, aliquam cumque ducimus qui ipsa in? ipsum dolor sit amet consectetur adipisicing elit. Quia molestiae ut optio recusandae"
        para2="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia molestiae ut optio recusandae nobis corrupti minus consecteturipsum dolor sit amet consectetur adipisicing elit. Quia molestiae ut optio recusandae officia necessitatibus vero?"
        imgSide="left"
      />
      <MultiGrid />
      <Counters />
      <LogoSlider />
      <POV />
      <RequestDemo />
    </PageLayout>
  );
};

export default OurSolutions;
