import { Fragment, useEffect, useState } from "react";
import PageLayout from "../../lauouts/pagelayout/PageLayout";
import "./FAQ.scss";
import Accordion from "react-bootstrap/Accordion";
import WOW from "wowjs";

const FAQ = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const faqData = [
    {
      id: 0,
      que: "What is Turbo Trade / Invoice Discounting ?",
      ans: [
        "BeMyCred’s Turbo Trade (TT) / Payable Invoice discounting is a closed-loop Supply Channel Financing (SCF) solution that provides instant payment to approved vendors/suppliers and extended credit to their Buyers.",
        "BeMyCred is leveraging cutting-edge technology to offer Working Capital (WC) finance to Dealers / Distributors / Stockists of approved Seller (i.e. vendor/supplier) under its TT Program. Our tailor-made solution helps Sellers (brands & enterprises) get instant payment/ on invoice due date against products sold, while their SME Buyers get financing for extended period, based on their credit profile, to purchase products on credit with an extended/flexible repayment window.",
        "It’s a practice of using company's unpaid invoices to raise working capital & fulfil its financial needs & business growth with selected Buyers. Traditionally, financial institutions including banks and NBFCs have been discounting/purchasing invoices for SMEs.",
        "Payable Invoice discounting involves transfer of rights on an asset (invoice) from the seller (i.e. vendor/supplier) to the financier (i.e. investor) at an agreed value."      ],
    },
    {
      id: 1,
      que: "How are TT / Invoice Discounting transactions processed?",
      ans: [
        "Lorem ipsuisicing elit. Explicabo adipisci, iusto natus a accusantium eaque tempora aliquid maxime ipsam expedita quam fuga dolores rem minima?",
        "SME business-owners looking to get their payable invoices (raised by their approved vendors/suppliers) financed with an extended payment terms (for Working Capital support) & grow their business with selected approved vendors/suppliers, can use our platform."
      ]
    },
    {
      id: 2,
      que: "How does TT / Invoice Discounting benefits Vendors/Suppliers (i.e. Brands & Large Enterprises) ?",
      ans:[
"Increase your Sales & Cash Conversion per Customer/Buyer by offering an extended credit period only on our financing platform",
"Save the Cash/Merchant discount rate to improve your bottom line",
"Seamless one-stop purchase experience for your Customer/Buyer",
"Quick & easy Digital Process without the hassles of lengthy documentations",
"Hassle-free API Integration & zero dropout at POS"
      ],
      
    },
    {
      id: 3,
      que: "How does TT / Invoice Discounting benefit Buyers/Borrowers (SME Businesses) ?",
      ans: [
        "Solve Working Capital Challenges by availing the flexibility to pay at a later date ",
        "Improve Credit Score - New-to-Credit borrowers can improve their creditworthiness by availing & repaying these loans"
      ]
    },
    {
      id: 4,
      que: "What is the eligibility criteria for Buyer / SME Business ?",
      ans: [
        "Business that procures goods/services from approved vendors/suppliers under the TT program, can avail the Payable Invoice finance/extension services. Eligibility and amount of financing is governed by the creditworthiness of the Buyer-Supplier business and therefore they should be willing to share their financial information and other related documents."
      ]
    },
    {
      id: 5,
      que: "Does BeMyCred have any upper/lower sanction limit for SME businesses ?",
      ans: ["There is no upper limit for sanctioned amounts for your invoices and it is solely dependent on the business’s requirements. Rather, the limit depends on the Buyer-Supplier’s past business relationship track-record, future business plans and eligibility as per BeMyCred’s criteria."],
    },
    {
      id: 5,
      que: "What is the maximum Invoice finance/extension tenor ?",
      ans: ["As we provide a short-term investment facility to our investors, we only offer tenor "],
    },
  ];

  const [category, setCategory] = useState(1);

  return (
    <PageLayout>
      <section className="faq">
        <div className="content_wrap p_t p_b">
          <div className="category_side">
            <div className="wow fadeInUp">
              <h2 className="gradient">FAQ's</h2>
            </div>
            <div className="tab_wraper">
              <button
                type="button"
                onClick={() => setCategory(1)}
                className={`wow fadeInUp ${category === 1 ? "active" : ""}`}
                data-wow-delay="0.1s"
              >
                All
              </button>
              <button
                type="button"
                onClick={() => setCategory(2)}
                className={`wow fadeInUp ${category === 2 ? "active" : ""}`}
                data-wow-delay="0.2s"
              >
               Product
              </button>
              <button
                type="button"
                onClick={() => setCategory(3)}
                className={`wow fadeInUp ${category === 3 ? "active" : ""}`}
                data-wow-delay="0.3s"
              >
               System Related
              </button>
            </div>
          </div>
          <div className="accordion_side">
            <Accordion defaultActiveKey="0">
              {faqData.map((data, i) => {
                return (
                  <Fragment key={data.id}>
                    <Accordion.Item
                      eventKey={`${data.id}`}
                      className="wow fadeInUp"
                      data-wow-delay={`${i * 0.1}s`}
                    >
                      <Accordion.Header>{data.que}</Accordion.Header>
                      <Accordion.Body>
                        {
                        data.ans.map((data, l) => {
                          return(
                            <p className="t-20" key={l} >{data}</p>
                          )
                        })
                        }
                      </Accordion.Body>
                    </Accordion.Item>
                  </Fragment>
                );
              })}
            </Accordion>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default FAQ;
