import { useContext, useEffect, useState } from "react";
import "./ThreeCompanies.scss";
import { images } from "../../../utils/images";

const ThreeCompanies = () => {
  const dataList = [
    {
      id: 1,
      count: "02/",
      title: "MeCred (DIFC) Ltd",
      link: "/mecred",
      img: images.logoMecred,
      mainTitle: "MeCred (DIFC) Ltd",
      para1:
        "MeCred (DIFC) Ltd. ('MeCred') stands for 'Middle East Credit' , specializing in local arrangement, structuring, and management of working capital solutions for Corprates/ SMEs. Located within the Dubai International Financial Centre (DIFC)",
      para2:
        "MeCred acts as a vital player to connect the B2B Ecosystem, including suppliers, distributers, and retailers. MeCred's operations are licensed under Category 4 DFSA License, ensuring compliance with local regulations.",
    },
    {
      id: 2,
      count: "03/",
      title: "Deftra DMCC",
      link: "/deftra",
      img: images.logoDeftra,
      mainTitle: "Deftra DMCC",
      para1:
        "Deftra ensures the completeness of onboarding documentation and operates BeMyCred portal, offering support to Creset and MeCred.",
      para2: "",
    },
    {
      id: 3,
      count: "01/",
      title: "Creset AG",
      link: "/creset",
      img: images.logoCreset,
      mainTitle: "Creset AG",
      para1:
        "Creset AG ('Creset') stands for “Credit” and “Settlement”, the Swiss-based office of BeMyCred regulated by SRO Polyreg, specialized in  international arrangement, structuring and management of working capital solutions for Small and Medium-sized Enterprises (SMEs) and Corporates.",
      para2:
        "Creset AG leverages robust and efficient Swiss regulatory and compliance framework, to promote confidence for international liquidity providers to fund the working capital solutions offered by BeMyCred to its clients based in the MENA region.",
    },
  ];

  const [num, setNum] = useState(1);

  const [view, setView] = useState(true);
  const setNumHandler = (count) => {
    if (count === num) {
      setNum(0);
    } else {
      setNum(count);
    }
  };

  useEffect(() => {
    setView(false);

    setTimeout(() => {
      setView(true);
    }, 600);
  }, [num]);

  return (
    <section className="three_companies">
      <div className="content_wrap p_b">
        <div className="wow fadeInUp">
          <h3 className="gradient my-5">Our Group Companies</h3>
        </div>

        {/* <div className="tribox_wrap">
          {dataList.map((data) => {
            return (
              <div
                className={`box ${
                  num === data.id ? "active" : ""
                } wow fadeInUp`}
                onClick={() => setNumHandler(data.id)}
                key={data.id}
                data-wow-delay={`${0.1 * data.id}s`}
              >
                <div className="label">
                  <p>{data.count}</p>
                  <p className="title wow fadeInUp">{data.title}</p>
                </div>
                <div
                  className="content_box"
                  style={view ? { opacity: "1" } : { opacity: "0" }}
                >
                  <div className="text">
                    <h3>{data.mainTitle}</h3>
                    {data.para1 && <p className="wow fadeInUp">{data.para1}</p>}
                    {data.para2 && <p className="wow fadeInUp">{data.para2}</p>}
                
                  </div>
                  <div className="img_wrap">
                    <img src={data.img} alt="img" />
                  </div>
                  <button
                    type="button"
                    className="read_more"
                    onClick={() => navigate(`${data.link}`)}
                  >
                    Read More
                  </button>
                </div>
              </div>
            );
          })}
        </div>


        <div className="tab_box">
          <Accordion defaultActiveKey="0">
            {dataList.map((data) => {
              return (
                <Accordion.Item eventKey={data.id - 1} key={data.id}>
                  <Accordion.Header>
                    <p>{data.count}</p>
                    <p className="title_text wow fadEInUp">{data.title}</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <h3>{data.mainTitle}</h3>
                    {data.para1 && <p className="wow fadeInUp">{data.para1}</p>}
                    {data.para2 && <p className="wow fadeInUp">{data.para2}</p>}                   
                    <img src={data.img} alt="img" />
                    <button
                      type="button"
                      className="read-more"
                      onClick={() => navigate(`${data.link}`)}
                    >
                      Read More
                    </button>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div> */}
        <div className="tri_grid_wrap">
          {dataList.map((data, i) => {
            return (
              <div
                className="company_card wow fadeInUp"
                data-wow-delay={`${i * 0.1}s`}
                key={data.id}
                onClick={() => {
                  window.open(data.link, "_blank", "noopener,noreferrer");
                }}
              >
                <div className="logo_box">
                  <img src={data.img} alt="img" />
                </div>

                <h3 className="gradient">{data.title}</h3>
                <button type="button">Know more</button>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default ThreeCompanies;
