import { Fragment } from "react";
import "./MecredPrivacyPolicy.scss";
import SubCompanyHeader from "../../components/subcompanyheader/SubCompanyHeader";
import SubCompanyFooter from "../../components/subcCompanyFooter/SubCompanyFooter";
import { NavLink } from "react-router-dom";
import { images } from "../../utils/images";

const MecredPrivacyPolicy = () => {
  return (
    <Fragment>
      <SubCompanyHeader logo={images.logoMecred} redirect="/mecred" />

      <section className="mecred_privacy_title">
        <div className="content_wrap">
          <div className="bred_crum">
            <h2 className="gradient">PRIVACY POLICY STATEMENT</h2>
          </div>
        </div>
      </section>

      <section className="mecred_privacy_content p_b">
        <div className="content_wrap">
          <h3 className="gradient">Introduction</h3>
          <p>
            Protecting the confidentiality and security of personal information
            is integral to the way in which <b>MeCred (DIFC) Ltd</b> (“<b>MDL</b>
            ”, “<b>we</b>” or “<b>us</b>”) conducts business worldwide. We at
            MDL are committed to safeguarding the privacy of our clients data
            and use it in accordance with our privacy principles set out below
            while providing our services to you. The following are key aspects
            of our updated Data Protection Policy that will help to explain the
            data processing practices of MDL
          </p>

          <p>
            In accordance with the DIFC Data Protection Law (“DPL”), MDL being
            the data controller for the purposes of the DPL and its application
            to its services, must provide you with information on how your
            personal data will be processed
          </p>

          <h3 className="gradient">
            Purposes of data processing and lawful basis of data processing
          </h3>

          <p>
            The personal data collected from you, provided by you or obtained
            from other sources on your behalf will be held, disclosed and
            processed by MDL for the purposes of offering you our services and
            maintaining the relationship with you
          </p>

          <p>
            We process personal data only when we have a lawful purpose for such
            processing. Presently, we use your personal data for the following
            lawful purposes:
          </p>

          <h3 className="gradient">A. Performance of Contract</h3>
          <ul>
            <li>
              <p>
                To carry out the relationship opening process upon receiving
                your application with the signed subscription
                agreements/investment agreements which may also require you to
                provide the required documentation
              </p>
            </li>
            <li>
              <p>
                Advising and administering your portfolio and any related
                account on an ongoing basis which involves your personal data
                for redemption, distribution of payments and for future
                subscriptions
              </p>
            </li>
            <li>
              <p>
                Disclosures to third parties identified in the said
                agreements/offering memorandum such as auditors, regulatory
                bodies, tax authorities and technology providers
              </p>
              <p>
                Recording, maintaining, storing and using recordings of
                telephone calls that you make to and receive from MDL or its
                delegates or duly appointed agents for processing and
                verification of instructions, providing advisory services and
                administration of your account(s) and any other matters related
                to your investment with MDL, dispute resolution, record keeping,
                security and/or training purposes
              </p>
            </li>

            <li>
              <p>Circulating periodic reports;</p>
            </li>

            <li>
              <p>Responding to queries or providing you support; </p>
            </li>
            <li>
              <p>
                For any other purpose as provided for in the agreements between
                you and us
              </p>
            </li>
          </ul>

          <h3 className="gradient">B. Compliance with legal obligations</h3>

          <p>
            Complying with any applicable legal, tax or regulatory obligations
            imposed on the portfolio manager, including legal obligations under
            the Companies Act, tax laws, the AML regulations and other relevant
            regulatory laws and regulations including but not limited to
          </p>

          <ul>
            <li>
              <p>
                AML: In line with anti-money laundering requirements under the
                DFSA AML regulations and other related regulations set by
                globally recognized AML agencies, all customers are required to
                be identified;
              </p>
            </li>
            <li>
              <p>
                CRS/FATCA: MDL is obliged to collect certain information about
                each investor’s tax arrangements. For CRS/FATCA reporting
                purposes, please note that in certain circumstances MDL may
                legally be obliged to share this information and other financial
                information with respect to an investor’s interest with the
                relevant tax authorities under relevant jurisdictions;
              </p>
            </li>

            <li>
              <p>
                Screening: MDL shall engage in screening for the purpose of AML
                regulations and counter terrorist financing legislations
                including PEP screening and screening required under UN, EU, UAE
                and other applicable sanctions regimes
              </p>
            </li>
          </ul>

          <h3 className="gradient">C. Legitimate interest</h3>

          <ul>
            <li>
              <p>Carrying out statistical analysis and market research</p>
            </li>
            <li>
              <p>
                Recording, maintaining, storing, and using recordings of
                telephone calls that you make to and receive from MDL.
              </p>
            </li>
          </ul>
          <p>
            Please note that where personal data is processed for purposes of
            legitimate interests, you have a right to object to such processing
            and MDL will no longer process the personal data unless MDL can
            demonstrate compelling legitimate grounds for the processing, which
            override your interests, rights and freedoms or for the
            establishment, exercise or defense of legal claims.
          </p>

          <h3 className="gradient">D. Consent</h3>
          <p>
            From time to time, MDL may send you information about financial
            markets etc., by telephone, by email or by other reasonable means of
            communication. You have the right to object to receiving such
            information.
          </p>
          <p>
            Your consent is not required to process your personal data for the
            purposes referenced above. However, in order to process your
            personal data for the purposes of direct marketing, MDL seeks your
            consent
          </p>

          <p>
            You have a right to withdraw this consent at any time. However, your
            withdrawal of consent will not affect the lawfulness of processing
            based on consent provided before its withdrawal.
          </p>

          <p>
            You can withdraw your consent by contacting MDL at the address given
            below:
          </p>

          <p>
            MeCred (DIFC) Ltd <br />
            Unit 410, Level 4, Liberty House, Dubai International Financial
            Centre,
            <br />
            Dubai, United Arab Emirates
          </p>

          <p>
            Email: <a href="mailto:vikas.j@mecred.ae">vikas.j@mecred.ae</a>
          </p>
          <h3 className="gradient">
            Disclosures to data processors and / or third parties
          </h3>
          <p>
            Personal data relating to you, which is collected from you or
            provided by you or on your behalf may be handled by MDL or their
            duly appointed agents for the purposes specified above
          </p>

          <p>
            These data processors will handle your information in accordance
            with applicable Data Protection Legislation
          </p>
          <p>
            MDL may disclose your personal data to other third parties where
            required by law or for legitimate business interests. This may
            include disclosure to third parties such as auditors, regulatory
            bodies, taxation authorities and technology providers.            
          </p>

          <h3 className="gradient">Transfers Abroad</h3>
          <p>
            Your data may be processed outside of the DIFC. Personal data
            collected from you or provided by you or on your behalf may be
            transferred outside of the originating jurisdiction or the DIFC,
            including to companies situated in countries which may not have the
            same standard of data protection laws as the DIFC. Such data
            transfers will only involve companies, whicharepartofthe same
            corporate group or acting as service providers to MDL.
          </p>

          <h3 className="gradient">Retention period</h3>
          <p>
            MDL will retain your personal data for as long as is needed to
            fulfil the purposes outlined in our Data Protection Policy, unless a
            longer retention period is required or permitted by law.
          </p>

          <h3 class="gradient">Security Measures to Protect your Data</h3>
          <p>
            We maintain reasonable and appropriate security measures to protect
            your personal data from loss, misuse, and unauthorized access,
            disclosure, alteration, and destruction. Our information security
            controls ensure that personal and financial data is well protected
            and accessible only to authorized personnel.
          </p>

          <h3 className="gradient">Your data protection rights</h3>
          <p>Your data protection rights include:</p>
          <ul>
            <li>
              <p>
                You have a right of access to and the right to amend and rectify
                your personal data.
              </p>
            </li>
            <li>
              <p>
                You have the right to have any incomplete personal data
                completed.
              </p>
            </li>
            <li>
              <p>
                You have a right to be forgotten (right of erasure of personal
                data).
              </p>
            </li>
            <li>
              <p>
                You have a right to restrict processing of your personal data.
              </p>
            </li>
            <li>
              <p>You have a right to data portability.</p>
            </li>
            <li>
              <p>
                You also have the right to object to processing where MDL is
                processing personal data for legitimate interests
              </p>
            </li>
            <li>
              <p>
                You have the right to withdraw your consent used for direct
                marketing
              </p>
            </li>
          </ul>

          <p>
            Additionally, you have a right to lodge a complaint with the
            relevant supervisory authority in the DIFC, of the alleged
            infringement if you consider that the processing of personal data
            relating to you carried out by MDL is not as per the regulation in
            force
          </p>
          <p>
            You may inform us of your wish to exercise your rights as outlined
            above by submitting your written request to us via:
          </p>
          <p>Mr. Vikas Jha</p>
          <p>
            Address: c/o MeCred (DIFC) Ltd <br />
            Unit 410, Level 4, Liberty House, Dubai International Financial
            Centre, Dubai,
            <br />
            United Arab Emirates
          </p>
          <p>
            Email: <a href="mailto:vikas.j@mecred.ae">vikas.j@mecred.ae</a>
          </p>

          <h3 className="gradient">Miscellaneous</h3>
          <p>
            We do not track your online activities across the internet. We do
            not use your personal data for automated decision making, including
            profiling. We do not sell or rent your personal data to third
            parties
          </p>

          <h3 className="gradient">Refusal to provide personal data</h3>
          <p>
            The provision of personal data by you, as outlined in the section
            titled “Purposes of processing and lawful basis for processing” is
            required for us to accept your application and manage and administer
            your business relationship with us, and so that we can comply with
            the contractual, tax, legal and regulatory requirements referenced
            above. Where you fail or refuse to provide such personal data, we
            may not be able to perform the contract we have entered into with
            you, or we may be prevented from complying with our legal
            obligations, in which case we will terminate the contract or
            business relationship with you with immediate effect
          </p>
        </div>
      </section>

      <SubCompanyFooter
        logo={images.logoMecred}
        address="MeCred (DIFC) Ltd 410, Liberty House, DIFC Dubai, United Arab Emirates"
        mecred={true}
        disclaimer={
          <p className="desclaim">
            “MeCred (DIFC) Ltd is a licensed financial service provider
            established in the Dubai International Financial Centre (“DIFC”) and
            regulated by the Dubai Financial Services Authority (“DFSA”). This
            email and any files transmitted with it are confidential, intended
            only for the use of the recipient(s), and may contain privileged
            information. If you are not the intended recipient, any review,
            retransmission, dissemination, or other use of, or taking of any
            action in reliance upon this information is strictly prohibited.
            MeCred (DIFC) Ltd is providing this communication for informational
            purposes only. It is not an offer or solicitation for any service or
            investment. This message is directed at professional clients and/or
            market counterparties as defined by the DFSA. If this does not
            describe you, please disregard this email. Investment-related
            information in this email is not comprehensive and should not be
            considered as an advice or recommendation. Recipients should conduct
            their own due diligence and consult a financial adviser for
            personalized advice, if required.For details on our data protection
            practices, refer to our Privacy Policy on our website. MeCred (DIFC)
            Ltd reserves the right to update its{" "}
            <NavLink to="/mecred/privacy-policy">
              Privacy Policy Statement
            </NavLink>
            , as required. If you have received this email in error, please
            contact the sender and delete the contents without delay.
          </p>
        }
        link1="/mecred/privacy-policy"
        linktext1="Privacy Policy Statement"
        email="info@mecred.ae"
      />
    </Fragment>
  );
};

export default MecredPrivacyPolicy;
