import { useEffect } from "react";
import PageLayout from "../../lauouts/pagelayout/PageLayout";

import Counters from "../ourSolutions/counters/Counters";
import LogoSlider from "../ourSolutions/logoslider/LogoSlider";
import WOW from "wowjs";
import LeftInfo from "./leftInfo/LeftInfo";
import { images } from "../../utils/images";
import Accord from "./accord/Accord";

const Client = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <PageLayout>
      {/* <LeftInfo
        title="Receivable Finance"
        l1=" We offer early payment to Vendors (Distributors / Resellers) for
      their sales of goods"
        l2="Funder pays the Vendor immediately after the invoice date and
      collects from the buyer on invoice due date"
        img={images.clientBannerA}
        classList="p_b p_t"
      />
      <Counters
        title="Benefits for Vendors (Distributors)"
        p1="Enhance liquidity & sales"
        p2="Improve cash flow forecasting & reduce Days of Receivable Outstanding (DRO)"
        p3="Beneficial accounting treatment"
        classList=""
      />

      <LeftInfo
        title="Payable Extension (Turbo Trade)"
        l1="Flagship program designed to support sales growth for distributors and SME customers."
        l2="Co-administered by buyer's distributor"
        l3="Funder pays the Vendor on or before invoice due date and collects from the buyer on extended payment date."
        img={images.clientBannerB}
        classList="p_t p_b"
      />
      <Counters
        title="Benefits for Vendors (Distributors)"
        p1="Enhances sales"
        p2="Assured payment on invoice due date"
        p3="Improved cashflow forecasting"
        classList="p_b"
      />
      <Counters
        title="Benefits for Buyers (SMEs)"
        p1="Enhances sales"
        p2="Increases days of payables outstanding (DPO)"
        p3="Beneficial accounting treatment"
        classList=""
      /> */}

<Accord />

      {/* <LogoSlider classList="p_b p_t" /> */}

      {/* <RequestDemo
        text="Explore the
      commercials and partnership opportunities with BeMyCred."
        btn="Partner with Us"
        redirect="/contactus"
      /> */}
    </PageLayout>
  );
};

export default Client;
