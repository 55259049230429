import { Fragment } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const PageLayout = ({children}) => {
  return (
    <Fragment> 
      <Header />
      <div className="header_filler"></div>
      {children}
      <Footer />
    </Fragment>
  );
};

export default PageLayout;
