import "./Expertise.scss";
import { images } from "../../../utils/images";

const Expertise = () => {
    const dataList = [
        {
          id: 0,
          img:images.iconM,
          title: "Advisory Support",
          text: "",
        },
        {
          id: 1,
          img:images.iconD,         
          title: "Operational Support",
          text: "",
        },
        {
          id: 2,
          img:images.iconB,
          title: "Collection Support",
          text: "",
        },
        
   
      ];
    return(
        <section className="expertise_sec p_b">
            <div className="content_wrap">
                <div className="exprtise_title">
                    <h3 className="gradient">Expertise & Offline Support</h3>
                </div>
            <div className="trade_grid">
          {dataList.map((data, j) => {
            return (
              <div
                className="info_card wow fadeInUp"
                key={data.id}
                data-wow-delay={`${0.1 * (j % 3)}s`}
              >
              <img src={data.img} alt="support" />
              <h3>{data.title}</h3>
               <p>{data.text}</p>
              </div>
            );
          })}
        </div>
            </div>
        </section>
    )
}

export default Expertise;