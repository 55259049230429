import { useEffect, useState } from "react";
import PageLayout from "../../lauouts/pagelayout/PageLayout";
import "./BookADemo.scss";
import PrimaryBtn from "../../components/primarybtn/PrimaryBtn";
import WOW from 'wowjs';

const BookADemo = () => {

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);



  const [success, setSuccess] = useState(false);

  const submitHandler = () => {
    setSuccess(true);

    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  return (
    <PageLayout>
      <section className="book_demo_landing">
        <div className="content_wrap p_t p_b">
          <div className="wow fadeInUp">
          <h1 className="gradient dark">
            Get a Demo
            <br />
            of Lorem
          </h1>
          </div>
        </div>
      </section>

      <section className="book_Demo_form">
        <div className="content_wrap p_t p_b">
          <div className="text">
            <p className="t-20 wow fadeInUp" data-wow-delay="0.1s">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat
              architecto placeat suscipit distinctio numquam dignissimos
              possimus inventore, minima dolores quasi!
            </p>
            <p className="t-20 wow fadeInUp" data-wow-delay="0.2s">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Aspernatur illo officiis rerum odio adipisci dolores iste
              voluptatem delectus, sit minima! Neque provident, optio recusandae
              adipisci eos aut dolores omnis animi assumenda suscipit natus,
              aliquid sequi.
            </p>
          </div>

<div className="form_box">
          <div className="form_wraper">
            <div className={`form_success ${success ? "open" : "close"}`}>
              <h3>Submit Success!</h3>
              <p className="t-210">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Blanditiis quos inventore tempora, corrupti dicta aliquam.
              </p>
            </div>
            <form>
              <h2 className="wow fadeInUp">Get in touch</h2>
              <input type="text" placeholder="Name" className="wow fadeInUp" data-wow-delay="0.1s"/>
              <input type="number" placeholder="Phone Number" className="wow fadeInUp" data-wow-delay="0.2s"/>
              <input type="email" placeholder="Email" className="wow fadeInUp" data-wow-delay="0.3s"/>
              <input type="text" placeholder="Company Name" className="wow fadeInUp" data-wow-delay="0.4s"/>
              <textarea placeholder="Question" className="wow fadeInUp" data-wow-delay="0.5s"/>
              <PrimaryBtn text="Submit" theme="dark" onClick={submitHandler} classList="wow fadeInUp" delay="0.5" />
            </form>
          </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default BookADemo;
