import { Fragment, useEffect, useState } from "react";
import PrimaryBtn from "../../components/primarybtn/PrimaryBtn";
import PageLayout from "../../lauouts/pagelayout/PageLayout";
import "./ContactUs.scss";
import WOW from "wowjs";

import { baseUrl, contact } from "../../utils/apidata";
import axios from "axios";

import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FieldError from "./FieldError/FieldError";

const ContactUs = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [questionError, setQuestionError] = useState("");

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const contactApi = async (value) => {
    setSuccess(true);
    setLoading(true);
    try {
      const res = await axios.post(`${baseUrl}/${contact}`, {
        ...value,
      });
      if (res?.data?.success) {
        setLoading(false);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      } else {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 2000);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);

      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
  };

  const initialValues = {
    name: "",
    mobile: "",
    email: "",
    company_name: "",
    question: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required!"),
    mobile: Yup.string().required("Number is required!"),
    email: Yup.string()
      .email("Invalid email formate!")
      .required("Email is required!"),
    company_name: Yup.string().required("Company name is required!"),
    question: Yup.string().required("Question is required!"),
  });

  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    contactApi(values);
    resetForm();
  };

  return (
    <PageLayout>
      <section className="contact_us">
        <div className="content_wrap p_b">
          <div className="contact_title wow fadeInUp">
            <h1 className="gradient dark">Contact us</h1>
          </div>
          <div className="bi_grid">
            <div className="form_wrap">
              <div className={`form_success ${success ? "open" : "close"}`}>
                {loading ? (
                  <p className="t-20">Loading...</p>
                ) : (
                  <Fragment>
                    {error ? (
                      <h3 className="error">Something went Wrong!</h3>
                    ) : (
                      <Fragment>
                        <h3 className="wow fadeInUp">Thanks, Your form has been submitted</h3>                       
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                <Form>
                  <h2 className="wow fadeInUp">Get in touch</h2>
                  <Field
                    type="text"
                    placeholder="Name"
                    className="wow fadeInUp"
                    data-wow-delay="0.1s"
                    name="name"
                    id="name"
                    autoComplete="off"
                  />
                  <ErrorMessage name="name" component={FieldError} />
                  <Field
                    type="number"
                    placeholder="Phone Number"
                    className="wow fadeInUp"
                    data-wow-delay="0.2s"
                    name="mobile"
                    id="mobile"
                    autoComplete="off"
                  />
                  <ErrorMessage name="mobile" component={FieldError} />
                  <Field
                    type="email"
                    placeholder="Email"
                    className="wow fadeInUp"
                    data-wow-delay="0.3s"
                    name="email"
                    id="email"
                    autoComplete="off"
                  />
                  <ErrorMessage name="email" component={FieldError} />
                  <Field
                    type="text"
                    placeholder="Company Name"
                    className="wow fadeInUp"
                    data-wow-delay="0.4s"
                    name="company_name"
                    id="company_name"
                    autoComplete="off"
                  />
                  <ErrorMessage name="company_name" component={FieldError} />
                  <Field
                    as="textarea"
                    placeholder="Question"
                    className="wow fadeInUp"
                    data-wow-delay="0.5s"
                    name="question"
                    id="question"
                    autoComplete="off"
                  />
                  <ErrorMessage name="question" component={FieldError} />
                  <PrimaryBtn
                    text="Submit"
                    type="submit"
                    theme="dark"
                    classList="wow fadeInUp"
                    delay="0.6s"
                  />
                </Form>
              </Formik>
            </div>

            <div className="address_side">
              <div className="address_grid">
                <div className="info_card">
                  <h3>Address</h3>
                  <p>
                    Level 5, Unit 504
                    <br />
                    Emirates Financial Towers,
                    <br />
                    Dubai International Financial Center (DIFC),
                    <br />
                    PO Box 34469, Dubai, UAE
                  </p>
                </div>

                <div className="info_card">
                  <h3>Contact</h3>
                  <div className="info_wrap">
                    <p>Email:</p>
                    <p>
                      <a href="mailto:info@finneva.com">info@finneva.com</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default ContactUs;
