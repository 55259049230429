import "./HomeLanding.scss";
import { images } from "../../../utils/images";
import Slider from "react-slick";
import { useState } from "react";
import {motion} from "framer-motion";

const HomeLanding = () => {
  const [slide, setSlide] = useState(0);
  const [status, setStatus] = useState(false);
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed:4000,
    cssEase: "linear",
    beforeChange: (current, next) => {
      setStatus(true);
    },
    afterChange: (current) => {
      setStatus(false);
      setSlide(current);
    },
  };

  return (
    <section className="home_landing">
      <div className="slide_wrap_box">
        <Slider {...settings}>
          <div className="slide_wrap">
            <div className="overlay"></div>
            <img src={images.salesGrowth} />
          </div>

          <div className="slide_wrap">
            <div className="overlay"></div>
            <img src={images.partnership} />
          </div>

          <div className="slide_wrap">
            <div className="overlay"></div>
            <img src={images.landingC} />
          </div>
        </Slider>

        <div className="slide_content">
          {slide === 0 && (
            <div className="content_box">              
              <motion.h1  
               initial={{ translateX: 150, opacity: 0 }}
               whileInView={{ translateX: 0, opacity: 1 }}
               transition={{
                 ease: "linear",
                 duration: 0.6,
               }}
              >
              Enabling Sales Growth
              </motion.h1>              
            </div>
          )}

          {slide === 1 && (
            <div className="content_box">
              <motion.h1
                  initial={{ translateX: 150, opacity: 0 }}
                  whileInView={{ translateX: 0, opacity: 1 }}
                  transition={{
                    ease: "linear",
                    duration: 0.6,
                  }}
              >
                Your Trusted Partner in Financial Solutions
              </motion.h1>
       
            </div>
          )}

          {slide === 2 && (
            <div className="content_box">
              <motion.h1 className="third"
                  initial={{ translateX: 150, opacity: 0 }}
                  whileInView={{ translateX: 0, opacity: 1 }}
                  transition={{
                    ease: "linear",
                    duration: 0.6,
                  }}
              >
              Bespoke solutions streamlining  granular operational processes
              </motion.h1>       
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default HomeLanding;
