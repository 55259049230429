 import { useNavigate } from "react-router-dom";
import "./ErrorPage.scss";
import PrimaryBtn from "../../components/primarybtn/PrimaryBtn";

 const ErrorPage = () => {

const navigate = useNavigate();

    return(
        <section className="error">
            <div className="content_wrap p_t p_b">
                <h1 className="gradient">404</h1>
                <h2>Something’s Not Right.</h2>
                <p>We Can’t Find The Page Your Are Looking For.</p>
                <PrimaryBtn
            text="Home"
            theme="dark"
            onClick={() => navigate("/")}
          />
            </div>
        </section>
        
    )
 }

    export default ErrorPage;    