import { createContext, useState } from "react";

export const appContext = createContext();

const AppContext = ({ children }) => {
  const [lightTheme, setLightTheme] = useState(false);

  let fname = "Ashish";
  return (
    <appContext.Provider value={{ fname, lightTheme, setLightTheme }}>
      {children}
    </appContext.Provider>
  );
};

export default AppContext;
