import "./MecredLanding.scss";
import { images } from "../../../utils/images";
import { Fragment } from "react";

const MecredLanding = () => {
  return (
    <Fragment>
      <section className="mecred_landing">
        <div className="content_wrap">
          <div className="text_wrap">
            <div className="wow fadeInUp">
              <h2 className="gradient">MeCred (DIFC) Ltd</h2>
              <h3 className="gradient">(Regulated by the DFSA)</h3>
            </div>

            <p className="wow fadeInUp" data-wow-delay="0.05s">
              MeCred's operations are licensed under Category 4 DFSA License as
              Arranger and Adviser on Credit, ensuring compliance with the DFSA
              Rules & Regulations. MeCred stands for “Middle East Credit”,
              specialized in local arrangement, structuring of working capital
              solutions for Small and Medium-sized Enterprises (SMEs) and
              Corporates.
            </p>

            <p className="wow fadeInUp" data-wow-delay="0.1s">
              Located within the Dubai International Financial Centre (DIFC),
              MeCred acts as a vital player to connect the local B2B Ecosystem,
              including suppliers, distributers, and Resellers.
            </p>

            {/* <p className="wow fadeInUp" data-wow-delay="0.15s">
              MeCred's operations are licensed under Category 4 DFSA License as
              Arranger and Adviser on Credit, ensuring compliance with local
              regulations.
            </p> */}
          </div>
          <div className="img_wrap">
            <img
              src={images.mecredBanner}
              alt="img"
              className="wow fadeInRight mt-4"
              data-wow-delay="0.1s"
            />
          </div>
        </div>
      </section>

      <section className="img_info">
        <div className="content_wrap p_b">
          <p className="wow fadeInUp spread" data-wow-delay="0.2s">
            Access to finance has been one of the greatest challenges facing
            small and medium enterprises (“<b>SMEs</b>”) across the globe, and
            particularly for the MENA region, where nearly 63 percent of the
            SMEs do not have access to finance. MeCred intends to arrange
            working capital solutions in the business to business (“<b>B2B</b>”)
            space specifically aimed at supporting SMEs’ growth. MeCred provides
            advisory to its clients on a range of solutions tailored to their
            financing needs, thereby assisting them in effectively managing any
            cash flow discrepancies. Additionally, MeCred also aids in securing
            the necessary funding to achieve the clients’ financial objectives.
            Through its offering, MeCred will provide its clients with access to
            local and international investors/funders.
          </p>

          <p className="wow fadeInUp spread mt-4">
            MeCred will arrange a diverse range of working capital & supply
            chain financing products and services for its clients. The services
            offered will aim to support the industry specific solutions
            arranging bespoke facilities for the corporates (<b>Suppliers</b>)
            as well as the SMEs (<b>Buyers</b>) purchasing their
            products/services(<b>Resellers</b>). The Company’s key business
            lines and focus areas are illustrated in figure below:
          </p>
          <img src={images.mecredDetail} alt="mecred" />
          <p>USP of MeCred’s Offering:</p>
          <ul>
            <li className="wow fadeInUp" data-wow-delay="0.05s">
              <p>
                UAE based set up with strong experience in regional and
                international trade and supply chain finance
              </p>
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.1s">
              <p>
                Regulated financial services company with focus on transparent
                governance and strong control ( data and processes)
              </p>
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.15s">
              <p>Access to network of local and international funders</p>
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.2s">
              <p>
                Proprietary technology helping disintermediate complex granular
                operational processes digitally
              </p>
            </li>
            <li className="wow fadeInUp" data-wow-delay="0.25s">
              <p>Industry and Supply Chain Led approach</p>
            </li>
          </ul>
        </div>
      </section>
    </Fragment>
  );
};

export default MecredLanding;
