import "./PrimaryBtn.scss";

const PrimaryBtn = ({text, theme, classList, onClick, delay, type}) => {

    // theme : "light" OR "dark"
        
    return(
        <button type={type ? type : "button"} className={`primary_btn ${theme} ${classList}`} data-wow-delay={`${delay}s`} onClick={onClick}>
            {text}
        </button>
    )
};

export default PrimaryBtn;