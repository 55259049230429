import "./BuildTrade.scss";
import { images } from "../../../utils/images";
import CenteredTitle from "../../../components/centeredTitle/CenteredTitle";

const BuildTrade = () => {
  const dataList = [
    {
      id: 0,
      img:images.iconH,
      title: "Multi purpose processes",
      text: "End-to-end processing capability to run payables as well as receivables-based programs seamlessly",
    },
    {
      id: 1,
      img:images.iconG,
      title: "Flexible",
      text: " Portal can be tailored to manage the credit aspects of each portfolio as per defined characteristics",
    },
    {
      id: 2,
      img:images.iconJ,
      title: "Data-driven decision making",
      text: "Processes and related notifications and alerts are automated",
    },
    {
      id: 3,
      img:images.iconK,
      title: "Real-time access",
      text: "All parties in the program have access via a website to all relevant information: balances, limits, utilization, payment behavior, aging, etc.",
    },
    {
      id: 4,
      img:images.iconI,
      title: "Next generation",
      text: "Digital onboarding and workflow management",
    },
    {
      id: 5,
      img:images.iconK,
      title: "Data privacy / Security",
      text: "Strict data protection as per regulatory guidelines. Servers are hosted on ISO certified cloud infrastructure",
    },
  ];
  return (
    <section className="build_trade p_t">
      <div className="content_wrap">
        <CenteredTitle 
        title="Origination as a Service"
        para="We originate industry specific managed, well assessed and
        lucrative trade and supply chain portfolio"
        />
        <div className="trade_grid">
          {dataList.map((data, j) => {
            return (
              <div
                className="info_card wow fadeInUp"
                key={data.id}
                data-wow-delay={`${0.1 * (j % 3)}s`}
              >
               <img src={data.img} alt="technologies" />
                <h3>{data.title}</h3>
                <p>{data.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default BuildTrade;
