import { useState } from "react";
import "./Counters.scss";
const Counters = ({title, p1, p2, p3, classList}) => { 

  return (
    <section className="counters_sec">
      <div className={`content_wrap ${classList}`}>
        <div className="wow fadeInUp">
          <h3 className="gradient lrb">{title}</h3>
        </div>
        <div className="counters_grid">
          <div className="counter">
            <p className="wow fadeInUp" data-wow-delay="0.1s">
             {p1}
            </p>
          </div>
          <div className="counter m">
            <div className="left_l"></div>
            <p className="wow fadeInUp" data-wow-delay="0.1s">{p2}</p>
            <div className="right_l"></div>
          </div>
          <div className="counter">        
          <p className="wow fadeInUp" data-wow-delay="0.1s">{p3}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Counters;
