import "./LogoSlider.scss";
import { images } from "../../../utils/images";
import Slider from "react-slick";

const LogoSlider = ({ classList }) => {
  const listData = [
    {
      id: 0,
      img: images.partnerA,
    },
    {
      id: 1,
      img: images.partnerB,
    },
    {
      id: 2,
      img: images.partnerC,
    },
    {
      id: 3,
      img: images.partnerD,
    },
    {
      id: 4,
      img: images.partnerE,
    },
    {
      id: 5,
      img: images.partnerF,
    },
    {
      id: 6,
      img: images.partnerG,
    },
    {
      id: 7,
      img: images.partnerH,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <section className={`logo_slider ${classList}`}>
      <div className="logo_title wow fadeInUp">
        <h2 className="gradient">Our Partners</h2>
      </div>
      <div className="slider_wrap wow fadeInUp">
        <Slider {...settings}>
          {listData.map((data) => {
            return (
              <div className="slide_img_wraper" key={data.id}>
                <div className="img_wrap">
                  <img src={data.img} alt="img" />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};
export default LogoSlider;
