import "./Accord.scss";
import Accordion from "react-bootstrap/Accordion";

const Accord = () => {
  return (
    <section className="accord_sec p_t p_b">
      <div className="content_wrap">
        <div className="acc_grid">
          <div className="accordion_side">
            <h3 className="gradient">For Buyers </h3>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="one" className="wow fadeInUp">
                <Accordion.Header>
                  <h3 className="gradient">What</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    As a Buyer, you can benefit from our payables solutions by:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Extending payment terms on your purchases with selected
                        suppliers OR
                      </p>
                    </li>
                    <li>
                      <p>
                        Avail Cash discount from your Suppliers by making early
                        payments
                      </p>
                    </li>
                  </ul>
                  <p>
                    This will allow you to enable your business growth on
                    attractive terms.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="two" className="wow fadeInUp">
                <Accordion.Header>
                  <h3 className="gradient">How</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Simply contact our office and we will provide you with the
                    details of how you can apply for the services. Important is
                    to consider that the services are related to your trades
                    with a specific supplier.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="three" className="wow fadeInUp">
                <Accordion.Header>
                  <h3 className="gradient">Terms</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The terms offered will be commensurate with your business
                    needs and working capital requirement.
                  </p>
                  <ul>
                    <li>
                      <p>
                        We consider the extension of your payables towards a
                        supplier by additional upto 90 days depending on your
                        credit and payment history, as well as growth plans, we
                        may be able to consider extending the terms beyond the
                        standard ones.
                      </p>
                    </li>
                    <li>
                      <p>
                        For early payment solution, the terms are tailored as
                        per your business requirement.
                      </p>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="accordion_side">
            <h3 className="gradient">For Suppliers </h3>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="one" className="wow fadeInUp">
                <Accordion.Header>
                  <h3 className="gradient">What</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    As a Seller, you can benifit from our receivables solutions
                    by:
                  </p>
                  <ul>
                    <li>
                      <p>Early Payment / Purchase of receivables</p>
                    </li>
                    <li>
                      <p>Offering Extension of terms to your growth Buyers</p>
                    </li>
                  </ul>
                  <p>
                    This will allow you to enable your business growth on
                    attractive terms.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="two" className="wow fadeInUp">
                <Accordion.Header>
                  <h3 className="gradient">How</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Simply contact our office and we will provide you with the
                    details of how you can apply for the services. Important is
                    to considerthat the services are related to your trades with
                    a specific supplier.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="three" className="wow fadeInUp">
                <Accordion.Header>
                  <h3 className="gradient">Terms</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The terms offered will be commensurate with your business
                    needs and working capital requirement. Depending on your
                    credit and payment history, as well as growth plans, we may
                    be able to tailor specific solutions.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Accord;
