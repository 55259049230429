import { useNavigate } from "react-router-dom";
import PrimaryBtn from "../../../components/primarybtn/PrimaryBtn";
import "./RequestDemo.scss";

const RequestDemo = ({text, btn, redirect}) => {

  const navigate = useNavigate();

  return (
    <section className="request_demo_sec p_t p_b">
      <div className="content_wrap wow fadeInUp">        
        <h3 className="gradient dark">
         {text}
        </h3>
        <div className="right">
          <PrimaryBtn
            text={btn}
            theme="light"
            onClick={() => navigate(redirect)}
          />
        </div>
      </div>
    </section>
  );
};

export default RequestDemo;
