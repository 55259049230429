import { Fragment, useEffect } from "react";
import "./CompanyDetails.scss";
import CompanyDetailLanding from "./companyDetailLanding/CompanyDetailLanding";
import PageLayout from "../../lauouts/pagelayout/PageLayout";
import InfoLR from "../ourSolutions/infoLR/InfoLR";
import RequestDemo from "../ourSolutions/requestdemo/RequestDemo";
import WhyChooseUs from "./whyChooseUs/WhyChooseUs";
import WOW from "wowjs";

const CompanyDetails = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <PageLayout>
      <CompanyDetailLanding />

      <InfoLR
        title="Vision: What we do"
        para1="Creset AG leverages robust and efficient Swiss regulatory and compliance framework, to promote confidence for international liquidity providers to fund the working capital solutions offered by BeMyCred to its clients based in the MENA region."
        para2="Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, consequuntur porro ab quia fugiat quas!"
        imgSide="left"
      />
      <WhyChooseUs />
      <RequestDemo
        text="Explore the
      commercials and partnership opportunities with BeMyCred."
        btn="Partner with Us"
        redirect="/contactus"
      />
    </PageLayout>
  );
};

export default CompanyDetails;
