import "./SaasSolution.scss";
import CenteredTitle from "../../../components/centeredTitle/CenteredTitle";
import { images } from "../../../utils/images";

const SaasSolution = () => {
  const dataList = [
    {
      id: 0,
      img:images.iconF,
      title: "Digital Onboarding",
      text: "",
    },
    {
      id: 1,
      img:images.iconN,
      title: "Approval Workflow-invoices",
      text: "",
    },
    {
      id: 2,
      img:images.iconD,
      title: "Dynamic Credit and compliance score",
      text: "",
    },
    {
      id: 3,
      img:images.iconC,
      title: "Bespoke processing capability",
      text: "",
    },
    {
      id: 4,
      img: images.iconA,
      title: "Recon &  Reporting Toolkit",
      text: "",
    },
    {
      id: 5,
      img:images.iconL,
      title: "Efficient Collections",
      text: "",
    },
  ];
  return (
    <section className="saas_solution p_t">
      <div className="content_wrap">
        <CenteredTitle
          title="Saas + Solutions"
          para="Plug and Play Technology assisted with advisory and operational support for faster rollout and scale up of product proposition."
        />
        <div className="saas_title">
          <h3 className="gradient light">Digital Solution</h3>
        </div>

        <div className="trade_grid">
          {dataList.map((data, j) => {
            return (
              <div
                className="info_card wow fadeInUp"
                key={data.id}
                data-wow-delay={`${0.1 * (j % 3)}s`}
              >
                <img src={data.img} alt="solutions" />
                <h3>{data.title}</h3>
                <p>{data.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default SaasSolution;
