import "./CompanyDetailLanding.scss";

const CompanyDetailLanding = ({ title, p1, p2, p3, banner }) => {
  return (
    <section className="commpany_detail_landing">
      <div className="content_wrap p_b">
        <div className="text_wrap">
          <div className="wow fadeInUp">
            <h2 className="gradient">{title}</h2>
          </div>

          <p className="wow fadeInUp" data-wow-delay="0.1s">
            {p1}
          </p>

          <p className="wow fadeInUp" data-wow-delay="0.15s">
            {p2}
          </p>
          {
            p3 && <p className="wow fadeInUp" data-wow-delay="0.3s">
            {p3}
          </p>
          }
        </div>
        <div className="img_wrap">
          <img
            src={banner}
            alt="img"
            className="wow fadeInRight"
            data-wow-delay="0.1s"
          />
        </div>
      </div>
    </section>
  );
};

export default CompanyDetailLanding;
