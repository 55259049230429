import PrimaryBtn from "../../../components/primarybtn/PrimaryBtn";
import { images } from "../../../utils/images";
import "./SolutionLanding.scss";

const SolutionLanding = ({title, p1, p2}) => {
  return (
    <section className="solution_landing">
      <div className="content_wrap p_t p_b">
        <div className="text_wrap">
          <div className="wow fadeInUp">
          <h1 className="gradient dark main_title">{title}</h1>
          </div>
          <p className="t-20 wow fadeInUp" data-wow-delay="0.1s">
           {p1}
          </p>
          <p className="t-20 wow fadeInUp" data-wow-delay="0.2s">
          {p2}
          </p>
          <PrimaryBtn
            text="Book A Demo"
            theme="light"
            classList="wow fadeInUp"
            onClick={() => console.log("Clicked")}
            data-wow-delay="0.4s"
          />
        </div>
        <div className="img_wrap">
            <img src={images.bisecA} alt="banner" className="wow zoomIn" />
        </div>
      </div>
    </section>
  );
};

export default SolutionLanding;
