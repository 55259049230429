import { Fragment, useEffect } from "react";

// Components
import PageLayout from "../../lauouts/pagelayout/PageLayout";
import HomeLanding from "./homeLanding/HomeLanding";
import ThreeCompanies from "./threecompanies/ThreeCompanies";
import OurSolution from "./ourSolution/OurSolution";
import Technologies from "./technologies/Technologies";


// Other
import WOW from "wowjs";
import AboutUs from "./aboutUs/AboutUs";

const Home = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  
  }, []);

  return (
    <Fragment>
      <PageLayout>
        <HomeLanding />
        <OurSolution />
        <Technologies />
        <AboutUs />
        <ThreeCompanies />
      </PageLayout>
    </Fragment>
  );
};

export default Home;
