import { Fragment } from "react";
import "./CenteredTitle.scss";

const CenteredTitle = ({ title, para }) => {
  return (
    <div className="centered_title">
      <div className="wow fadeInUp">
        <h2 className="gradient">{title}</h2>
      </div>
      {para && <p className="wow fadeInUp" data-wow-delay="0.15s">{para}</p>}
    </div>
  );
};

export default CenteredTitle;
