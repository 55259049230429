import { Fragment } from "react";
import "./MultiGrid.scss";

const MultiGrid = ({ list }) => {
  return (
    <section className="multi_grid_sec">
      <div className="content_wrap p_b">
        <div className="title">
          <div className="wow fadeInUp">
            <h2 className="gradient">
              Lorem ipsum dolor sit amet, consectetur adipisicing
            </h2>
          </div>
          id hic saepe molestias nemo animi a quaerat architecto quidem
        </div>
        <div className="multi_grid wow fadeInUp">
          {
            list ?  
            <Fragment>
              <div className="grid_card light">
            <h3>Turbo Trade/Pay Financing</h3>
            <ul>
              <li>
                <p className="t-16">
                  Turbo Trade/Pay Financing is our dynamic ecosystem-based
                  finance solution designed to catalyze sales growth.
                </p>
              </li>
              <li>
                <p className="t-16">
                  Co-sponsored with Vendors to assist growth Buyers for their
                  payables through access to trade related extended payment
                  terms.
                </p>
              </li>
              <li>
                <p className="t-16">
                  Increases sales growth whilst maintaining healthy credit
                  portfolio.
                </p>
              </li>
              <li>
                <p className="t-16">
                  End-to-End digitized onboarding with thorough platform based
                  assessment (compliance and credit)
                </p>
              </li>
            </ul>
          </div>
            </Fragment>
            : 
            <Fragment>
              <div className="grid_card light">
            <h3>Lorem ipsum dolor sit.</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quaerat incidunt aut voluptatibus dolores eaque dolor libero. Quae architecto nam iusto animi similique libero at?</p>
            </div>
            </Fragment>
          }

         {
          list ?      
         <div className="grid_card">
         <h3>AR Financing (Factoring)</h3>
         <p className="t-16">
           We offer programmes that cover purchase of receivables from
           Vendors with early payment. Funders may provide this facility on a
           non-recourse, limited recourse, or full recourse basis, depending
           on the structure or requirements of the supply chain.
         </p>
       </div>:
            <div className="grid_card">
            <h3>Lorem ipsum dolor sit.</h3>
            <p className="t-16">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab tempore ipsa dolores nobis. Eum eius facilis corporis sed numquam repellendus officiis earum modi laboriosam qui!
            </p>
          </div>
         }
        </div>
      </div>
    </section>
  );
};

export default MultiGrid;
