import "./AboutUs.scss";

const AboutUs = () => {
  return (
    <section className="about_us p_t" id="aboutus">
      <div className="content_wrap">
        <h2 className="gradient text-center pb-5">About us</h2>

        <p className="wow fadeInUp" data-wow-delay="0.05s">
          Finneva Holdings Ltd. (Finneva) is a Dubai International Financial
          Centre (DIFC, UAE) registered holding company. Finneva, through its
          subsidiary companies, provides financial services focused on
          increasing financial inclusion and access to finance in a structured
          and specialized manner across markets in MENA region. The initiatives
          aims to transform lives and enhance access to affordable financial
          services through a customer focused proposition and be a leader in
          this space.
        </p>
        <p className="wow fadeInUp" data-wow-delay="0.1s">
          Through its B2B offering, the initiatives seeks to address challenges
          faced by businesses in these markets through arrangement of 'digital
          credit' and 'frictionless processes' servicing their financial needs.
          It caters to businesses through its B2B Trade Finance service by
          bridging financing gap for its clients. The offerings enable
          innovative Industry led, be-spoke credit solutions for B2B Businesses
          by dis-intermediating complex granular operational processes.
        </p>
        <p className="wow fadeInUp" data-wow-delay="0.15s">
          The principles of offering are:
        </p>

        <ul>
          <li className="wow fadeInUp" data-wow-delay="0.2s">
            <p>
              Transversal Platform: Create a multi-geography and multi-industry
              processing and financing platform.
            </p>
          </li>
          <li className="wow fadeInUp" data-wow-delay="0.25s">
            <p>
              360<sup>o</sup> Approach to Due Diligence: Assist with management
              of credit, compliance and transactional services, based on our
              team's decades of experience.
            </p>
          </li>
          <li className="wow fadeInUp" data-wow-delay="0.3s">
            <p>
              Embedded Financing Solutions: Create Ecosystem finance proposition
              by leveraging Supply Chain linkages and strength.
            </p>
          </li>
          <li className="wow fadeInUp" data-wow-delay="0.35s">
            <p>
              Tailored Solutions: Bespoke solutions aligned to industry and
              client's supply chain needs.
            </p>
          </li>
        </ul>

        <h3 className="gradient my-5">Team</h3>

        <p className="wow fadeInUp" data-wow-delay="0.1s">
          Our Team consists of highly experienced professionals with many years
          of experience in the trade servicing sector having managed very large
          leading worldwide programs from the servicing and financing side.
          Click on{" "}
          <a href="https://www.linkedin.com/company/finneva/" target="_blank">
           Finneva Linkedin page
          </a>{" "}
          to know more about team.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
