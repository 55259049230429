import "./Creset.scss";
import { Fragment } from "react";
import SubCompanyHeader from "../../components/subcompanyheader/SubCompanyHeader";
import { images } from "../../utils/images";
import CompanyDetailLanding from "../companiesDetail/companyDetailLanding/CompanyDetailLanding";
import SubCompanyFooter from "../../components/subcCompanyFooter/SubCompanyFooter";

const Creset = () => {
  return (
    <Fragment>
      <SubCompanyHeader logo={images.logoCreset} />
      <CompanyDetailLanding
        banner={images.cresetBanner}
        title="Creset AG"
        p1='Creset Ltd ("Creset") stands for “Credit” and “Settlement”, the Swiss-based office of B2B working capital initiative regulated by SRO Polyreg, specialized in international arrangement, structuring and management of working capital solutions for Small and Medium-sized Enterprises (SMEs) and Corporates.'
        p2="Creset, acting as a sub-arranger, leverages on the strong and effective regulatory and compliance framework of Switzerland to bolster confidence among international liquidity providers. This strategic approach facilitates the funding of working capital solutions provided by B2B working capital initiative to its clients in the MENA region, providing for an effective connection between international funders and MENA region clients."
      />

      <SubCompanyFooter
        logo={images.logoCreset}
        address="Creset Ltd, Baarerstrasse 14 6300 Zug, Switzerland"
        disclaimer=""
        link1=""
        linktext1="Privacy Policy Statement"
        email="info@creset.ch"
      />
    </Fragment>
  );
};
export default Creset;
