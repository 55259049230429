import "./OurSolution.scss";
import { images } from "../../../utils/images";
import PrimaryBtn from "../../../components/primarybtn/PrimaryBtn";
import CenteredTitle from "../../../components/centeredTitle/CenteredTitle";
import { useNavigate } from "react-router-dom";

const OurSolution = () => {
  const dataList = [
    {
      id: 0,
      img: images.homeClient,
      title:"For Businesses",
      text: "We specialize in arranging working capital solutions in the dynamic business-to-business (B2B) landscape, focused on the SMEs.",
      go:"/solutions/businesses"
    },
    {
      id: 1,
      img: images.homeInstitution,
      title:"For Financial Institutions",
      text: "Enhance Your Trade Finance Operations with Our Digital Fulfilment (SaaS+) Solution Our SaaS+ solution is tailored for funders seeking to improve their trade finance services.",
      go:"/solutions/institution"
    },
  ];

  const navigate = useNavigate();

  return (
    <section className="our_solution">
      <div className="content_wrap">
        <CenteredTitle
        title="Our Solutions"
        p=""
        />      
        <div className="bi_grid">
          {dataList.map((data, i) => {
            return (
              <div className="info_card wow fadeInUp" key={data.id}  data-wow-delay={`${0.1*i}s`}>
                <img src={data.img} alt="" />
                <h3>{data.title}</h3>
                <p>{data.text}</p>
                <PrimaryBtn text="Know More" theme="light" onClick={() => navigate(`${data.go}`)} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default OurSolution;
