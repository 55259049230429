import { Fragment } from "react";
import "./SubCompanyHeader.scss";
import { NavLink } from "react-router-dom";

const SubCompanyHeader = ({ logo, links, redirect }) => {
  return (
    <Fragment>
      <section className="sub_company_header">
        <div className="content_wrap">
          <div className="company_logo_wrap">
            <NavLink to={redirect}>
              <img src={logo} alt="logo" className="finneva" />
            </NavLink>
          </div>
         
        </div>
      </section>    
    </Fragment>
  );
};

export default SubCompanyHeader;
