import { useEffect } from "react";
import "./FinancialInstitution.scss";
import PageLayout from "../../lauouts/pagelayout/PageLayout";
import WOW from "wowjs";
import { images } from "../../utils/images";
import BuildTrade from "./buildtrade/BuildTrade";
import SaasSolution from "./saasSolution/SaasSolution";
import Expertise from "./expertise/Expertise";

const FinancialInstitution = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <PageLayout>
      <BuildTrade />
      <SaasSolution />

      <section>
        <div className="content_wrap">
          <div className="plus_wrap">
            <img src={images.plus} alt="Plus" className="plus" />
          </div>
        </div>
      </section>
      <Expertise />
    </PageLayout>
  );
};

export default FinancialInstitution;
