import iconA from "../assets/images/icons/iconA.png";
import iconB from "../assets/images/icons/iconB.png";
import iconC from "../assets/images/icons/iconC.png";
import iconD from "../assets/images/icons/iconD.png";
import iconE from "../assets/images/icons/iconE.png";
import iconF from "../assets/images/icons/iconF.png";
import iconG from "../assets/images/icons/iconG.png";
import iconH from "../assets/images/icons/iconH.png";
import iconI from "../assets/images/icons/iconI.png";
import iconJ from "../assets/images/icons/iconJ.png";
import iconK from "../assets/images/icons/iconK.png";
import iconL from "../assets/images/icons/iconL.png";
import iconM from "../assets/images/icons/iconM.png";
import iconN from "../assets/images/icons/iconN.png";


import salesGrowth from "../assets/images/home/salesgrowth.webp";
import partnership from "../assets/images/home/partnership.webp";


import logo from "../assets/images/logo.png";



// Home
import landingC from "../assets/images/home/finneva-3.png";
import homeClient from "../assets/images/home/solutions-home-client.png";
import homeInstitution from "../assets/images/home/solutions-home-institution.png";
import avtar from "../assets/images/avtar.jpg";
import logoEurop from "../assets/images/europ.png";
import mEast from "../assets/images/meast.png";
import finneva from "../assets/images/finneva.svg";
import partnerA from "../assets/images/partners/ComplyAdvantage-2019-Dark-Inline.png";
import partnerB from "../assets/images/partners/Dubai_International_Financial_Centre_logo.png";
import partnerC from "../assets/images/partners/Microsoft_Azure_Logo.svg.png";
import partnerD from "../assets/images/partners/aws.png";
import partnerE from "../assets/images/partners/et.png";
import partnerF from "../assets/images/partners/onfido-logo.png";
import partnerG from "../assets/images/partners/polyreg.jpeg";
import partnerH from "../assets/images/partners/efr.png";



import logoCreset from "../assets/images/subcompany/CRESET.png";
import logoDeftra from "../assets/images/subcompany/DEFTRA.png";
import logoMecred from "../assets/images/subcompany/MECRED.png";

import ramesh from "../assets/images/leaders/ramesh.webp";
import raman from "../assets/images/leaders/raman.webp";
import saurabh from "../assets/images/leaders/saurabh.webp";
import vikas from "../assets/images/leaders/vikas.png";
import ishan from "../assets/images/leaders/ishan.png";
import vikram from "../assets/images/leaders/vikram.png";

import clientBannerA from "../assets/images/banner/payable-extension.png";
import clientBannerB from "../assets/images/banner/receivable-finance.png";



import plus from "../assets/images/plus.png";

import mecredBanner from "../assets/images/banner/mecred.webp";
import cresetBanner from "../assets/images/banner/creset.webp";
import deftraBanner from "../assets/images/banner/deftra.webp";

import mecredDetail from "../assets/images/subcompany/mecredInfo.png";

export const images = {
  iconA,
  iconB,
  iconC,
  iconD,
  iconE,
  iconF,
  iconG,
  iconH,
  iconI,
  iconJ,
  iconK,
  iconL,
  iconM,
  iconN,

  avtar,
  logo,
  landingC,
  logoEurop,
  mEast,

  finneva,

  partnerA,
  partnerB,
  partnerC,
  partnerD,
  partnerE,
  partnerF,
  partnerG,
  partnerH,

  

  logoCreset,
  logoDeftra,
  logoMecred,

  ramesh,
  raman,
  saurabh,
  vikas,
  ishan,
  vikram,

  clientBannerA,
  clientBannerB,
  homeClient,
  homeInstitution,


  plus,
  salesGrowth,
  partnership,

mecredBanner,
cresetBanner,
deftraBanner,

mecredDetail,
};
