import { Fragment } from "react";
import SubCompanyHeader from "../../components/subcompanyheader/SubCompanyHeader";
import { images } from "../../utils/images";
import "./Deftra.scss";
import CompanyDetailLanding from "../companiesDetail/companyDetailLanding/CompanyDetailLanding";
import SubCompanyFooter from "../../components/subcCompanyFooter/SubCompanyFooter";

const Deftra = () => {

  return (
    <Fragment>
    <SubCompanyHeader logo={images.logoDeftra}/>
    <CompanyDetailLanding
    banner={images.deftraBanner}
      title="Deftra DMCC"
      p1="Deftra DMCC ('Deftra') serves as the local backbone for operational back-office activities of B2B working capital initiative, responsible for programme management, pre-onboarding, pre-credit rating, and collections. As an affiliate company, Deftra's role is limited to providing intra-group services."
      p2="Deftra ensures the completeness of onboarding documentation and operates B2B working capital initiative portal, offering support to Creset and MeCred."      
    />

    <SubCompanyFooter
      logo={images.logoDeftra}
      address="Deftra DMCC Unit no. 2675, DMCC Business Centre, Level 1 Jewellery and Gemplex 3 Dubai, United Arab Emirates"    
      disclaimer="" 
      link1=""
      linktext1="Privacy Policy Statement"
      email="deftra@bemycred.com"
    />
  </Fragment>
  );
};
export default Deftra;
