import "./POV.scss";
import { images } from "../../../utils/images";

const POV = () => {
  const povData = [
    {
      id: 0,
      img: images.bisecA,
      title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi!",
    },
    {
      id: 1,
      img: images.bisecA,
      title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi!",
    },
    {
      id: 2,
      img: images.bisecA,
      title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi!",
    },
  ];

  return (
    <section className="pov_sec">
      <div className="content_wrap p_t p_b">
        <div className="wow fadeInUp">
        <h2 className="gradient">Our Point Of View</h2>
        </div>
        <div className="tri_grid">
          {povData.map((data, k) => {
            return (
              <div className="pov_card wow fadeInUp" key={data.id} data-wow-delay={`${0.1*k}s`}>
                <div className="img_wrap">
                  <img src={data.img} alt="img" />
                </div>
                <h3>{data.title}</h3>
                <button>Read Blog</button>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default POV;
