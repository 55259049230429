import { Fragment,useEffect } from "react";
import "./Mecred.scss";
import SubCompanyHeader from "../../components/subcompanyheader/SubCompanyHeader";
import CompanyDetailLanding from "../companiesDetail/companyDetailLanding/CompanyDetailLanding";
import SubCompanyFooter from "../../components/subcCompanyFooter/SubCompanyFooter";

import { images } from "../../utils/images";
import { NavLink } from "react-router-dom";
import MecredLanding from "./MecredLanding/MecredLanding";
import WOW from 'wowjs';

const Mecred = () => {

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <Fragment>
      <SubCompanyHeader logo={images.logoMecred} redirect="/mecred" />
  

      <MecredLanding />

      <SubCompanyFooter
        logo={images.logoMecred}
        address="MeCred (DIFC) Ltd 410, Liberty House, DIFC Dubai, United Arab Emirates"
        mecred={true}
        disclaimer={
          <p className="desclaim">
            “MeCred (DIFC) Ltd is a licensed financial service provider
            established in the Dubai International Financial Centre (“DIFC”) and
            regulated by the Dubai Financial Services Authority (“DFSA”). This
            email and any files transmitted with it are confidential, intended
            only for the use of the recipient(s), and may contain privileged
            information. If you are not the intended recipient, any review,
            retransmission, dissemination, or other use of, or taking of any
            action in reliance upon this information is strictly prohibited.
            MeCred (DIFC) Ltd is providing this communication for informational
            purposes only. It is not an offer or solicitation for any service or
            investment. This message is directed at professional clients and/or
            market counterparties as defined by the DFSA. If this does not
            describe you, please disregard this email. Investment-related
            information in this email is not comprehensive and should not be
            considered as an advice or recommendation. Recipients should conduct
            their own due diligence and consult a financial adviser for
            personalized advice, if required.For details on our data protection
            practices, refer to our Privacy Policy on our website. MeCred (DIFC)
            Ltd reserves the right to update its{" "}
            <NavLink to="/mecred/privacy-policy">
              Privacy Policy Statement
            </NavLink>
            , as required. If you have received this email in error, please
            contact the sender and delete the contents without delay.
          </p>
        }
        link1="/mecred/privacy-policy"
        linktext1="Privacy Policy Statement"
        email="info@mecred.ae"
      />
    </Fragment>
  );
};
export default Mecred;
