import "./InfoLR.scss";
import { images } from "../../../utils/images";
import { useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { appContext } from "../../../appContext/AppContext";

const InfoLR = ({ title, para1, para2, para3, imgSide, list, secId }) => {
  const location = useLocation();
  const { lightTheme } = useContext(appContext);

  const [theming, setTheming] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setTheming(true);
    } else {
      setTheming(false);
    }
  }, []);

  return (
    <section className={`info_lr ${theming && !lightTheme ? "dark" : ""}`} id={secId}>
      <div className={`content_wrap ${imgSide} p_t p_b`}>
        <div className="img_wrap">
          <img
            src={images.bisecA}
            alt="img"
            className={`wow ${
              imgSide === "left" ? "fadeInLeft" : "fadeInRight"
            }`}
          />
        </div>
        <div className="text_wrap">
          <div className="wow fadeInUp">
            <h2 className={`gradient  ${theming && !lightTheme ? "dark" : ""}`}>
              {title}
            </h2>
          </div>
          <p className="t-18 wow fadeInUp" data-wow-delay="0.1s">
            {para1}
          </p>
          <p className="t-18 wow fadeInUp" data-wow-delay="0.2s">
            {para2}
          </p>
          <p className="t-18 wow fadeInUp" data-wow-delay="0.3s">
            {para3}
          </p>
          {list && (
            <ul>
              <li><p>Creset AG</p></li>
              <li><p> MeCred DIFC Ltd.</p></li>
              <li><p>Deftra DMCC</p></li>
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};

export default InfoLR;
